<template>
  <div>
    <div class="d-flex justify-content-end mt-0" />
    <b-card
      no-body
    >
      <div
        class="m-2"
      >
        <b-row>
          <span
            class="pl-2"
          >
            <h2 class="text-primary">{{ allScheduleSlots.length > 0 ? allScheduleSlots[0].pmp_ads.name: null }}</h2>
          </span>
        </b-row>
      </div>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.limit"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allGroups-table"
        hover
        selectable
        :per-page="paginationObject.limit"
        :select-mode="selectMode"
        :items="allScheduleSlots"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
      >
        <!-- Column: id -->
        <template #cell(ID)="data">
          <span class="text-nowrap">
            {{ data.item.id }}
          </span>
        </template>
        <template #cell(Device_ID)="data">
          <span class="text-nowrap">
            {{ data.item.device.id }}
          </span>
        </template>
        <!-- Column: User -->
        <!-- Column: Email -->
        <template #cell(Device_Name)="data">
          <span
            class="text-nowrap d-inline-block text-truncate"
          >
            {{ data.item.device.name }}
          </span>
        </template>
        <template
          #cell(Start_Date_Time)="data"
        >
          <span
            class="text-nowrap d-inline-block"
            style="max-width: 200px;"
          >
            {{ getDateFromDateTime(data.item.start_date_time) }}-{{ getTimeFromDateTime(data.item.start_date_time) }}
          </span>
        </template>
        <template
          #cell(End_Date_Time)="data"
        >
          <span
            class="text-nowrap d-inline-block"
            style="max-width: 200px;"
          >
            {{ getDateFromDateTime(data.item.end_date_time) }}-{{ getTimeFromDateTime(data.item.end_date_time) }}
          </span>
        </template>
        <!-- Column: Status -->
        <template
          #cell(TARGET_ADS)="data"
        >
          {{ data.item.target_ads_count || '--' }}
        </template>
        <template
          #cell(APPROVED_TARGET_ADS)="data"
        >
          {{ data.item.approved_target_ads_count || '--' }}
        </template>
        <template
          #cell(Status)="data"
        >
          <span>
            <span
              :class="
                data.item.status === PmpSchedulerStatusEnum.ToBePlayed ? 'font-small-3 pending-tag-class' : data.item.status === PmpSchedulerStatusEnum.Qualified ? 'font-small-3 approval-tag-class' : data.item.status === PmpSchedulerStatusEnum.Expired ? 'font-small-3 rejected-tag-class' : 'font-small-3 played-tag-class' "
            >{{ data.item.status }}</span>
          </span>
        </template>
        <template
          #cell(Paid_Impressions)="
            data"
        >
          {{ data.item.paid_impressions || '--' }}
        </template>
        <template
          #cell(Free_Impressions)="data"
        >
          {{ data.item.free_impressions || '--' }}
        </template>
        <template
          #cell(Qualified_Time)="data"
        >
          <span
            class="text-nowrap d-inline-block"
            style="max-width: 200px;"
          >
            {{ getDateFromDateTime(data.item.qualified_time) }}-{{ getTimeFromDateTime(data.item.qualified_time) || '--' }}
          </span>
        </template>
        <template
          #cell(Played_At)="data"
          class="d-flex flex-row justify-content-around"
        >
          <span
            class="text-nowrap d-inline-block"
            style="max-width: 200px;"
          >
            {{ getDateFromDateTime(data.item.played_at) }}-{{ getTimeFromDateTime(data.item.played_at) || '--' }}
          </span>
        </template>
        <template
          #cell(Expiry_Time)="data"
        >
          <span
            class="text-nowrap d-inline-block"
            style="max-width: 200px;"
          >
            {{ getDateFromDateTime(data.item.expiry_time) }}-{{ getTimeFromDateTime(data.item.expiry_time) || '--' }}
          </span>
        </template>
      </b-table>
    </b-card>
    <CustomPagination
      v-if="allScheduleSlots.length"
      :total="paginationObject.total"
      :per-page="paginationObject.limit"
      :page="paginationObject.offset"
      @perPageChanged="perPageChanged"
      @pageChanged="pageChanged"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BTable,
  VBToggle,
  BSkeletonTable,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import CustomPagination from '@/components/common/CustomPagination.vue'
import PmpSchedulerStatusEnum from '@/common/enums/pmpSchedulerStatusEnum'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { getTimeFromDateTime, getDateFromDateTime } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BTable,
    BSkeletonTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      AccountTypes,
      PmpSchedulerStatusEnum,
      active: false,
      sortBy: 'STATUS',
      searchQuery: '',
      spinner: false,
      allScheduleSlots: [],
      deleteUserId: null,
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'ID' },
        { key: 'Device_ID', label: 'Device ID' },
        { key: 'Device_Name', label: 'Device Name' },
        { key: 'Start_Date_Time', label: 'Start Date Time' },
        { key: 'End_Date_Time', label: 'End Date Time' },
        // { key: 'APPROVED_TARGET_ADS' },
        { key: 'Status', label: 'Status' },
        { key: 'Free_Impressions', label: 'Free Impressions' },
        { key: 'Paid_Impressions', label: 'Paid Impressions' },
        { key: 'Qualified_Time', label: 'Qualified Time' },
        { key: 'Played_At', label: 'Played Time' },
        { key: 'Expiry_Time', label: 'Expired At' },
      ],
      selectMode: 'single',
      selected: [],
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  async mounted() {
    await this.getPmpAdsSchedulerAnalytics(this.paginationObject)
  },
  methods: {
    getTimeFromDateTime,
    getDateFromDateTime,
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getPmpAdsSchedulerAnalytics(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getPmpAdsSchedulerAnalytics(this.paginationObject)
    },
    getPmpAdsSchedulerAnalytics({ offset, limit }) {
      if (this.isUserAdmin) {
        this.spinner = true
        this.$axios.get(`/admin/pmp-ad/scheduler/analytics/${this.$route.params.id}?offset=${offset - 1}&limit=${limit}&search_query=&status=`)
          .then(({ data }) => {
            this.allScheduleSlots = data.data.results
            this.paginationObject.total = data.data.total
            this.spinner = false
          }).catch(() => { this.$swal('Businesses not found!') })
      } else {
        this.spinner = true
        this.$axios.get(`/pmp-ads/scheduler/analytics/${this.$route.params.id}?offset=${offset - 1}&limit=${limit}&search_query=&status=`)
          .then(({ data }) => {
            this.allScheduleSlots = data.data.results
            this.paginationObject.total = data.data.total
            this.spinner = false
          }).catch(() => { this.$swal('Scheduler not found!') })
      }
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .circular_image {
    border-radius: 40px;
    background-color: #eeedfd;
  }
  </style>
